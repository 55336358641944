import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit {

  @Input() src: string;
  @Input() name: string;

  constructor() { }

  ngOnInit(): void {
  }

}
