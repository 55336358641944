<app-header></app-header>
<section class="bg-gray-800 pattern py-20">
    <div class="max-w-5xl px-6 mx-auto text-center">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 class="text-3xl font-semibold text-red-500">
                    {{this.domopique.name}}
                </h3>

                <p class="text-red-500">Réalisé de {{this.domopique.date_debut}} à {{this.domopique.date_fin}}</p>
            </div>
            <!--body-->

            <br><br>
            <img class="w-auto h-1/2 mx-auto" src="{{this.domopique.image_description }}">

            <br><br>

            <div class="bg-white relative p-6 flex-auto">
                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                   Ce projet consiste à pouvoir observer des informations envoyées par des capteurs, branchés sur des Raspberry Pi, via une interface web. Il a été réalisé en groupe de 4 en deuxième année de DUT informatique.
                 <p>

                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                Me concernant, j'ai réalisé:
            </p>


                <ul class="my-4  text-lg list-none text-gray-600">
                    <li> * une partie de l'installation des Raspberry Pi et de leurs capteurs</li>
                    <li>* la quasi totalité de l'application web php</li>
                    <li>* une grosse partie du serveur NodeJs</li>
                    <li>* la mise en place de la base de données MongoDB</li>
                    <li>* l'assemblage du robot et le développement des différents programmes Python qui lui sont associés.</li>
                </ul>

                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                    J'ai réalisé une    <a class="text-red-500" href="{{this.domopique.externalUrls[1].src}}" target="_blank">{{this.domopique.externalUrls[1].text}}</a>
                    qui présente ce projet. J'avais déjà fait cette vidéo en 2016 mais je l'ai modifiée cette année afin de changer la musique.
                </p>

                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                    Les musiques présentes dans cette vidéo ont été composées par Thibaud Willm.

                    Si vous voulez écouter sa musique
                     <a class="text-red-500" href="{{this.domopique.externalUrls[0].src}}" target="_blank"> {{this.domopique.externalUrls[0].text}}</a>.
                </p>
                <br>
            </div>

            <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>

