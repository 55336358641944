import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {

  constructor() { }

  publication: string;

  cgu: string;

  intellectualProperty: string;

  website: string = 'https://ysaline.willm.dev';

  ngOnInit(): void {


  }

}
