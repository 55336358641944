import {NgModule} from '@angular/core';
import {RouterModule, Routes,} from '@angular/router';

import {HomeComponent} from "./front/home/home.component";
import {PageDomopiqueComponent} from "./front/page-domopique/page-domopique.component";
import {LegalNoticeComponent} from "./shared/legal-notice/legal-notice.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'projects/domopique', component: PageDomopiqueComponent},
  {path: 'mentions-legales', component: LegalNoticeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
