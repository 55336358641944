import { Component, OnInit } from '@angular/core';
import {$t} from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  name = 'Ysaline Willm';

  jobName = 'Développeuse Laravel - Angular';

  firstname = 'Ysaline';
  lastname = 'Willm';

  constructor() { }

  linkedinIcon = '/assets/images/icons/linkedin.svg';
  linkedinLink = 'https://www.linkedin.com/in/ysaline-willm';

  ngOnInit(): void {
  }

}
