<header class="bg-white shadow border-t-4 border-red-500">
    <div class="container mx-auto px-6 py-4">
        <div class="flex items-center justify-between">
            <div>
                <a class="flex items-center text-gray-800 hover:text-red-500" href="#">
                    <svg class="h-6 w-6 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                    </svg>

                    <span class="mx-3 font-medium text-sm md:text-base">{{firstname}} {{lastname}}</span>
                </a>
            </div>
            <div class="flex items-center -mx-2">
                <a class="flex items-center mx-2 text-gray-800 hover:text-red-500" href="{{linkedinLink}}" target="_blank">
                    <img class="h-5 w-5 sm:h-6 sm:w-6 fill-current" src="{{linkedinIcon}}"/>
                </a>
            </div>
        </div>
    </div>
</header>
