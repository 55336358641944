<section class="bg-gray-800 pattern py-20">
    <div class="max-w-5xl px-6 mx-auto text-center">
        <h2 class="text-2xl font-semibold text-white">Projets</h2>

        <br><br>
        <img class="mx-auto w-auto h-28" src="{{srcConstruction}}"/>
        <br><br>

        <p class="text-gray-300">Cette partie est en cours de construction ... </p>

        <div class="flex items-center justify-center mt-10">
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

                <div *ngFor="let project of projects" class="max-w-xs w-full">
                    <div class="flex items-center justify-center h-56 bg-white">
                        <img *ngIf="project.name == 'Domopique'" routerLink="{{project.url}}" class="w-auto h-56" src="{{project.img}}">

                        <img *ngIf="project.name != 'Domopique'" class="w-auto h-56" src="{{project.img}}">
                    </div>

                    <a class="block bg-gray-700 mt-5 rounded-md overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        <div class="py-2 px-3 text-center text-sm" routerLink="{{project.url}}">
                            <p class="text-gray-300">{{project.name}}</p>
                        </div>
                    </a>

                </div>
            </div>
        </div>

<!--        <div class="flex items-center justify-center mt-12">-->
<!--            <a class="flex items-center text-white hover:underline hover:text-gray-200" href="#">-->
<!--                <span>View More On Github</span>-->

<!--                <svg class="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>-->
<!--                </svg>-->
<!--            </a>-->
<!--        </div>-->
    </div>
</section>
