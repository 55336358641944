<section class="bg-white py-20">
    <div class="max-w-5xl px-6 mx-auto text-center container">

        <h2 class="text-2xl font-semibold text-gray-800">Formations</h2>

        <br>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-11 w-11 mx-auto" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
        </svg>
        <br>

        <div *ngFor="let formation of formations" class="flex flex-col items-center justify-center mt-6">
            <a class="max-w-2xl w-full block bg-white shadow-md rounded-md border-t-4 border-red-500 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                <div class="flex items-center justify-between px-4 py-2">
                    <span class="block text-gray-600 font-light text-sm">{{formation.date}}</span>
                    <h3 class="text-lg font-medium text-gray-700">{{formation.label}}</h3>
                    <span class="block text-gray-600 font-light text-sm">{{formation.place}}</span>
                </div>
            </a>
            <br>
        </div>
    </div>
</section>
