import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
  avatar = '/assets/images/avatar.svg';
  firstname = 'Ysaline';
  lastname = 'Willm';

  jobName = 'Développeur Senior Fullstack Laravel & Vue Js';

  constructor() { }

  ngOnInit(): void {
  }

}
