import { Component, OnInit } from '@angular/core';
import {formatDate} from "@angular/common";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  firstname: string = 'Ysaline';
  lastname: string = 'Willm';

  linkedinIcon: string= '/assets/images/icons/linkedin.svg';
  linkedinLink: string = 'https://www.linkedin.com/in/ysaline-willm';

  legalNoticeLink: string = 'mentions-legales';

  year: string = '';

  constructor() { }

  ngOnInit(): void {
    let now = new Date().toISOString();
    this.year = formatDate(now, 'yyyy', 'en-US');
  }

}
