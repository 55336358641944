import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.scss']
})
export class HobbiesComponent implements OnInit {

  racine = '/assets/images/hobbies/';
  extension = '.svg';

  hobbies = [
    {
      'name': 'Musique',
      'src': 'music',
    },
    {
      'name': 'Cinéma',
      'src': 'movies',
    },
    {
      'name': 'Cuisine',
      'src': 'cuisine',
    },
    {
      'name': 'Violon',
      'src': 'violin',
    },
    {
      'name': 'Trompette',
      'src': 'trumpet',
    },
    {
      'name': 'Flûte traversière',
      'src': 'flute',
    },
    {
      'name': 'Vélo',
      'src': 'bike',
    },
    {
      'name': 'Domotique',
      'src': 'automation',
    },
    {
      'name': 'Robotique',
      'src': 'robot',
    },
    {
      'name': 'Raspberry Pi',
      'src': 'raspberry',
    },
    {
      'name': 'Lecture',
      'src': 'book',
    },
    {
      'name': 'Bricolage',
      'src': 'tools',
    },
  ];

  constructor() { }

  ngOnInit(): void {
    for (let hobby of this.hobbies) {
      hobby.src = this.racine + hobby.src + this.extension;
    }
  }

}
