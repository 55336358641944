import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  racine = '/assets/images/logos/';
  extension = '.svg';

  technos = [
    {
      'name': '',
      'src': 'php',
    },
    {
      'name': 'Laravel',
      'src': 'laravel',
    },
    {
      'name': 'Lumen',
      'src': 'lumen',
    },
    {
      'name': '',
      'src': 'html',
    },
    {
      'name': '',
      'src': 'css',
    },
    {
      'name': '',
      'src': 'tailwindcss',
    },
    {
      'name': 'Bootstrap',
      'src': 'bootstrap',
    },
    {
      'name': 'JavaScript',
      'src': 'javascript',
    },
    {
      'name': 'TypeScript',
      'src': 'typescript',
    },
    {
      'name': '',
      'src': 'nodejs',
    },
    {
      'name': '',
      'src': 'jquery',
    },
    {
      'name': 'VueJs',
      'src': 'vuejs',
    },
    {
      'name': '',
      'src': 'angular',
    },
    {
      'name': '',
      'src': 'ionic',
    },
    {
      'name': '',
      'src': 'docker',
    },
    {
      'name': 'MySQL',
      'src': 'mysql',
    },
    {
      'name': '',
      'src': 'mariadb',
    },
    {
      'name': '',
      'src': 'mongodb',
    },
    {
      'name': 'Redis',
      'src': 'redis',
    },
    {
      'name': '',
      'src': 'git',
    },
    {
      'name': 'Linux',
      'src': 'linux',
    },
    {
      'name': '',
      'src': 'bash',
    },
    {
      'name': 'Raspberry PI',
      'src': 'raspberry',
    },
  ];

  constructor() { }

  ngOnInit(): void {

    for (let techno of this.technos) {
      techno.src = this.racine + techno.src + this.extension;
    }
  }

}
