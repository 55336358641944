<footer class="bg-white">
    <div class="container mx-auto px-6 py-4">
        <div class="flex justify-between items-center">
            <div>
                <div class="text-gray-600">&copy; {{firstname}} {{lastname}} {{year}}</div>
            </div>

            <a class="flex items-center mx-2 text-gray-800 hover:text-red-500" href="{{legalNoticeLink}}">
                <p class="text-gray-600">Mentions légales</p>
            </a>


            <div class="flex items-center -mx-2">
                <a class="flex items-center mx-2 text-gray-600 hover:text-red-500" href="#">
                    <a class="flex items-center mx-2 text-gray-800 hover:text-red-500" href="{{linkedinLink}}" target="_blank">
                        <img class="h-5 w-5 sm:h-6 sm:w-6 fill-current"src="{{linkedinIcon}}"/>
                    </a>
                </a>
            </div>
        </div>
    </div>
</footer>
