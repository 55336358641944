import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  about: string = 'Passionnée par l\'informatique depuis toute petite, je me consacre en parallèle ' +
      'de ma vie professionnelle à une spécialisation Laravel / Angular / Ionic .' +
      ' Je suis également passionnée de domotique.';

  perso: string = 'J\'habite en Alsace.';

  constructor() { }

  ngOnInit(): void {
  }

}
