import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formations',
  templateUrl: './formations.component.html',
  styleUrls: ['./formations.component.scss']
})
export class FormationsComponent implements OnInit {

  constructor() { }

  diploma = '/assets/images/diploma.png';

  formations = [
    {
      'label': 'Licence Professionnelle CDED',
      'place': 'IUT Robert Schuman (Illkirch)',
      'date': '09/2017 - 06/2018',
    },
    {
      'label': 'DUT informatique',
      'place': 'IUT Robert Schuman (Illkirch)',
      'date': '09/2015 - 06/2017',
    },
    {
      'label': 'Séjour linguistique anglais',
      'place': 'Afrique du Sud',
      'date': '01/2015 - 03/2015',
    },
    {
      'label': 'Lycée Erckmann Chatrian - BAC S spé maths',
      'place': 'Phalsbourg',
      'date': '09/2011 - 07/2014',
    },
  ];


  ngOnInit(): void {
  }

}
