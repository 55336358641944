<section class="bg-gray-800 pattern py-20 my-4">
<div class="max-w-5xl px-6 mx-auto text-center container">
    <h2 class="text-2xl font-semibold text-white">Expériences professionnelles</h2>
<br>
    <svg xmlns="http://www.w3.org/2000/svg" class="h-11 w-11 mx-auto" viewBox="0 0 20 20" fill="white">
            <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
            <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
        </svg>
    <br>

        <div *ngFor="let experience of experiences" class="flex flex-col items-center justify-center mt-6">
            <a class="max-w-2xl w-full block bg-white shadow-md rounded-md border-t-4 border-red-500 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                <div class="flex items-center justify-between px-4 py-2">
                    <span class="block text-gray-600 font-light text-sm">{{experience.date}}</span>
                    <h3 class="text-lg font-medium text-gray-700">{{experience.label}}</h3>
                    <span class="block text-gray-600 font-light text-sm">{{experience.place}}</span>
                </div>
            </a>
            <br>
        </div>
    </div>
</section>
