import { Injectable } from '@angular/core';
import projects from '../../../assets/json/projects.json';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  protected projectsList = projects;

  constructor() {}

  getProjects(): any
  {
    return this.projectsList;
  }

  getProject(name: string): any {
    for (let project of this.projectsList) {
      if (project.name == name) {
        return project;
      }
    }

    return [];
  }

}
