<section class="bg-white py-20">
    <div class="max-w-5xl px-6 mx-auto text-center container">

        <h2 class="text-2xl font-semibold text-gray-800">Compétences</h2>

        <br>
        <div class="grid grid-cols-6 items-center justify-center mt-6 mx-auto">
            <app-icon class="flex items-center justify-start px-2 py-2" *ngFor="let techno of technos" name="{{techno.name}}" src="{{techno.src}}"></app-icon>
        </div>

    </div>
</section>
