import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './shared/footer/footer.component';
import {HomeComponent} from './front/home/home.component';
import {HeaderComponent} from './front/header/header.component';
import {MenuComponent} from './front/menu/menu.component';
import {FormationsComponent} from './front/formations/formations.component';
import {JobsComponent} from './front/jobs/jobs.component';
import {AboutMeComponent} from './front/about-me/about-me.component';
import {PersonalsProjectsComponent} from './front/personals-projects/personals-projects.component';
import {PresentationComponent} from './front/presentation/presentation.component';
import {SkillsComponent} from './front/skills/skills.component';
import {IconComponent} from './tools/icon/icon.component';
import {HobbiesComponent} from './front/hobbies/hobbies.component';
import { PageDomopiqueComponent } from './front/page-domopique/page-domopique.component';
import {LegalNoticeComponent} from './shared/legal-notice/legal-notice.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    HeaderComponent,
    MenuComponent,
    FormationsComponent,
    JobsComponent,
    AboutMeComponent,
    PersonalsProjectsComponent,
    PresentationComponent,
    SkillsComponent,
    IconComponent,
    HobbiesComponent,
    PageDomopiqueComponent,
      LegalNoticeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
