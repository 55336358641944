<app-header></app-header>

<section class="bg-white mt-20">
    <div class="max-w-2xl px-6 text-left mx-auto">
        <h2 class="text-3xl font-semibold text-gray-800">Mentions légales</h2>

        <br>

        <div class="justify-center">
            <h2>Définitions</h2>
            <br>
            <p><b>Client :</b> tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des présentes conditions générales.<br>

                <br>
                <b>Prestations et Services : </b> <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> met à disposition des Clients :</p>

            <br>
            <p><b>Contenu : </b> Ensemble des éléments constituants l’information présente sur le Site, notamment textes – images – vidéos.</p>

            <br>
            <p><b>Informations clients :</b> Ci après dénommé « Information (s) » qui correspondent à l’ensemble des données personnelles susceptibles d’être détenues par <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> pour la gestion de votre compte, de la gestion de la relation client et à des fins d’analyses et de statistiques.</p>

            <br>
            <p><b>Utilisateur :</b> Internaute se connectant, utilisant le site susnommé.</p>

            <br>
            <p><b>Informations personnelles :</b> « Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</p><br>
            <p>Les termes « données à caractère personnel », « personne concernée », « sous traitant » et « données sensibles » ont le sens défini par le Règlement Général sur la Protection des Données (RGPD : n° 2016-679)</p>
            <br><br>

            <h2 class="text-2xl font-semibold text-gray-800">1. Présentation du site internet.</h2>

            <br>
            <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>  l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi:

                <br><br>
            </p><p><strong>Propriétaire</strong> :   Ysaline Willm<br><br>
            <strong>Responsable publication</strong> : Ysaline Willm<br><br>
            Le responsable publication est une personne physique ou une personne morale.<br><br>
            <strong>Webmaster</strong> : Ysaline WILLM<br><br>
            <strong>Hébergeur</strong> : OVH – 2 rue Kellermann 59100 Roubaix 1007<br>
        </p><br>

            <div ng-bind-html="linkHTML"><p>Les mentions légales sont issues du modèle proposé par le  <a href="https://fr.orson.io/1371/generateur-mentions-legales" title="générateur gratuit offert par Orson.io">générateur gratuit offert par Orson.io</a></p></div><br>

            <h2 class="text-2xl font-semibold text-gray-800">2. Conditions générales d’utilisation du site et des services proposés.</h2><br>

            <p>Le Site constitue une œuvre de l’esprit protégée par les dispositions du Code de la Propriété Intellectuelle et des Réglementations Internationales applicables.
                Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre compte tout ou partie des éléments ou travaux du Site.</p> <br>

            <p>L’utilisation du site <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>  sont donc invités à les consulter de manière régulière.</p> <br>

            <h2 class="text-2xl font-semibold text-gray-800">3. Propriété intellectuelle et contrefaçons.</h2>

            <br>

            <p><a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> est propriétaire des droits de propriété intellectuelle et/ou détient les droits d’usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, icônes et sons.</p><br>
            <p>
                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> .</p><br>

            <p>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p><br>

            <h2 class="text-2xl font-semibold text-gray-800">4. Limitations de responsabilité.</h2>

            <br>
            <p><a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>  agit en tant qu’éditeur du site. <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>   est responsable de la qualité et de la véracité du Contenu qu’il publie. </p><br>

            <p><a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site internet <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> , et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p><br>

            <p><a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>  ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a></p> <br>

            <h2 class="text-2xl font-semibold text-gray-800">5. Liens hypertextes « cookies » et balises (“tags”) internet</h2>
            <br>
            <h3 class="text-1xl font-semibold text-gray-800">5.1. « COOKIES »</h3>
            <br>
            <p>
                Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.</p><br>
            <p>
                <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> d’améliorer le contenu du Site, de la navigation de l’Utilisateur.</p><br>
            <p>
                Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.</p><br>
            <p>
                Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.</p><br>
            <p>
                Le cas échéant, <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.</p><br>
            <p>
                À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.</p><br>
            <p>
                Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site de <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>, Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).</p><br>
            <p>
                Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> dépose ce type de cookies.</p><br>

            <h3 class="text-1xl font-semibold text-gray-800">Article 5.2. BALISES (“TAGS”) INTERNET</h3>
            <br>
            <p>
                <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.</p><br>
            <p>
                Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci.
            </p><br>
            <p>
                Cette technologie permet à <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’Utilisateur. </p><br>
            <p>
                Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a>, et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.</p>
            <p><br>

            </p> <h2 class="text-2xl font-semibold text-gray-800">6. Droit applicable et attribution de juridiction.</h2>
            <br>
            <p>
                Tout litige en relation avec l’utilisation du site <a class="text-gray-800 hover:text-red-500" href="{{website}}">{{website}}</a> est soumis au droit français.
                En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents.</p>
        </div>

    </div>
</section>


<app-footer></app-footer>
