import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  experiences = [
    {
      'label': 'Développeur Senior Fullstack Laravel & Vue Js',
      'place': 'REALIZ3D (Paris)',
      'date':  'Depuis 05/2022',
    },
    {
      'label': 'Développeur Fullstack Laravel & Vue JS',
      'place': 'Ziqy (Paris)',
      'date': '07/2021 - 05/2022',
    },
    {
      'label': 'Développeur backend Laravel',
      'place': 'Freshmile (Entzheim)',
      'date': '07/2019 - 07/2021',
    },
    {
      'label': 'Développeur Fullstack Laravel & Angular',
      'place': 'Sdv Plurimédia (Strasbourg)',
      'date': '09/2018 - 03/2019',
    },
    {
      'label': 'Développeur Fullstack Laravel & Angular (contrat en alternance)',
      'place': 'Sdv Plurimédia',
      'date': '07/2017 - 08/2018',
    },
    {
      'label': 'Développeur Fullstack Laravel & Angular (stage)',
      'place': 'Sdv Plurimédia',
      'date': '04/2017 - 06/2017',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
