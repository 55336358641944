import { Component, OnInit } from '@angular/core';
import {ProjectsService} from "../../services/projects/projects.service";
import {$t} from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-personals-projects',
  templateUrl: './personals-projects.component.html',
  styleUrls: ['./personals-projects.component.scss']
})
export class PersonalsProjectsComponent implements OnInit {

  racine: string = '/assets/images/projects/';

  extension: string = '.png';

  externalLink: boolean = false;

  projectModal:{'name': '', 'description', 'images': {}}[] =  [];

  projects = [];

  srcConstruction:string = "/assets/images/icons/construction.svg";

  showModal:boolean = false;

  constructor(private projectsService: ProjectsService) { }

  ngOnInit(): void {
    this.projects = this.projectsService.getProjects();

    for (let project of this.projects) {
      project.img = this.racine + project.img + this.extension;
    }
  }

  toggleModal(project) {
    this.showModal = !this.showModal && project.name === 'Domopique';
    this.projectModal = project;

    if (typeof project.externalUrls !== 'undefined') {
      this.externalLink = true;
    }

  }

}
