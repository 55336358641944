import { Component, OnInit } from '@angular/core';
import {ProjectsService} from "../../services/projects/projects.service";

@Component({
  selector: 'app-page-domopique',
  templateUrl: './page-domopique.component.html',
  styleUrls: ['./page-domopique.component.scss']
})
export class PageDomopiqueComponent implements OnInit {

  constructor(private projectsService: ProjectsService) { }

  domopique: any;

  ngOnInit(): void {
    this.domopique = this.projectsService.getProject('Domopique');
  }

}
