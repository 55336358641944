<main class="font-sans bg-white">
    <div>
        <app-header></app-header>
        <app-presentation></app-presentation>
        <app-about-me></app-about-me>
        <app-formations></app-formations>
        <app-jobs></app-jobs>
        <app-skills></app-skills>
        <app-personals-projects></app-personals-projects>
        <app-hobbies></app-hobbies>
        <app-footer></app-footer>
    </div>
</main>
