<section class="bg-white mt-20">
    <div class="max-w-2xl px-6 text-center mx-auto">
        <h2 class="text-3xl font-semibold text-gray-800">Bonjour, je m'appelle <span class="bg-red-500 text-white rounded px-1">{{firstname}}</span>,
            je suis <span class="bg-red-500 text-white rounded px-1">{{jobName}}</span>
        </h2>
        <p class="text-gray-600 mt-4">Bienvenue sur mon portfolio !
            <br> Ici vous trouverez mon CV, ainsi qu'un aperçu de certains de mes projets personnels.</p>

        <div class="flex items-end justify-center mt-16">
            <img src="{{avatar}}">
        </div>
    </div>
</section>
